<template><div><h1 id="unbricking-flashing-with-a-suzyqable" tabindex="-1"><a class="header-anchor" href="#unbricking-flashing-with-a-suzyqable"><span>Unbricking/Flashing with a SuzyQable</span></a></h1>
<h2 id="requirements" tabindex="-1"><a class="header-anchor" href="#requirements"><span>Requirements</span></a></h2>
<ul>
<li>A ChromeOS device with CCD (closed-case debugging) enabled on one of the USB-C ports. If your device uses CR50 for the firmware write protection, then it has CCD capability.</li>
</ul>
<div class="hint-container warning">
<p class="hint-container-title">NOTE</p>
<p>These instructions do not apply to any device which is locked/managed. Enterprise and/or EDU enrollment locks out CCD functionality completely.</p>
</div>
<ul>
<li>A USB-C debug cable (<a href="https://www.sparkfun.com/products/retired/14746" target="_blank" rel="noopener noreferrer">aka Suzy-Q cable</a>)</li>
<li>The device must have the CCD flags factory reset (as per instructions to <RouteLink to="/docs/firmware/wp/disabling.html#using-closed-case-debugging-ccd-using-a-suzyqable">Disable write protect with a SuzyQable</RouteLink>), or the battery must be unplugged/disconnected from the mainboard.</li>
<li>Another device running Linux, preferably a current Debian/Ubuntu-based distro</li>
</ul>
<h2 id="hardware-disassembly" tabindex="-1"><a class="header-anchor" href="#hardware-disassembly"><span>Hardware Disassembly</span></a></h2>
<p>As above, this is only needed if you failed to factory reset the CCD flags if you didn't follow the guide to <RouteLink to="/docs/firmware/wp/disabling.html#using-closed-case-debugging-ccd-using-a-suzyqable">Disable write protect with a SuzyQable</RouteLink>. While this is somewhat device-specific, the main points are the same:</p>
<ul>
<li>Disconnect all external power</li>
<li>Remove bottom cover (screws are often located under rubber feet or strips)
<ul>
<li>Some Chromebooks open up through the back and some through the keyboard, and as mentioned in <RouteLink to="/docs/firmware/wp/disabling.html#disconnecting-the-battery">Disabling write protect via Battery</RouteLink>. On keyboard, you have to pry it out and remove a ribbon wire under the keyboard.</li>
</ul>
</li>
<li>Disconnect the internal battery</li>
</ul>
<h2 id="prepping-to-flash" tabindex="-1"><a class="header-anchor" href="#prepping-to-flash"><span>Prepping to Flash</span></a></h2>
<p>Most any 64-bit Debian/Ubuntu based distro should work here, but this guide will use a Ubuntu live session booted from USB (since its version of flashrom supports Suzy-Q flashing).</p>
<p>Let's get to it:</p>
<ol>
<li>Boot your Linux environment (Ubuntu live USB or later recommended)</li>
<li>Connect to the internet</li>
<li>Open a (non-root) terminal/shell window, change to home directory
<ul>
<li><code v-pre>cd;</code></li>
</ul>
</li>
<li>Install flashrom via apt:
<ul>
<li><code v-pre>sudo apt update</code></li>
<li><code v-pre>sudo apt install flashrom</code></li>
</ul>
</li>
<li>Connect the USB-C end of the Suzy-Q cable to the CCD port on your ChromeOS device (usually left USB-C port) and the USB-A end to your Linux device</li>
<li>Verify the cable is properly connected:
<ul>
<li><code v-pre>ls /dev/ttyUSB*</code>
<ul>
<li>This command should return 3 items: <code v-pre>ttyUSB0</code>, <code v-pre>ttyUSB1</code>, and <code v-pre>ttyUSB2</code>.</li>
<li>If not, then your cable is connected to the wrong port or is upside down; adjust and repeat command until output is as expected</li>
</ul>
</li>
</ul>
</li>
<li>Set the CCD state to open:
<ul>
<li><code v-pre>echo &quot;ccd open&quot; | sudo tee -a /dev/ttyUSB0 &gt; /dev/null</code></li>
</ul>
</li>
<li>Determine file to be flashed
<ul>
<li>Depending on your desired use for the device, you have 3 options for flashing:</li>
<li>The backup file of the stock firmware created by my Firmware Utility Script
<ul>
<li>If using this, simply copy the file from USB into the home directory of the live USB user</li>
</ul>
</li>
<li>The custom UEFI firmware for the device
<ul>
<li>If you were flashing the UEFI firmware when things went sideways, then that's the easiest way to proceed. You can download the UEFI firmware for your device by examining the <a href="https://github.com/MrChromebox/scripts/blob/master/sources.sh" target="_blank" rel="noopener noreferrer">sources.sh file from the Firmware Utility Script GitHub repo</a>. Simply concatenate the device-specific filename to the Full ROM base path:
<ul>
<li><code v-pre>wget &lt;Full ROM base path&gt;&lt;device specific filename&gt;</code></li>
<li>Example for the Acer Chromebook 14 CB3-431 (EDGAR)</li>
<li><code v-pre>wget https://mrchromebox.tech/files/firmware/full_rom/coreboot_tiano-edgar-mrchromebox_20180827.rom</code></li>
<li>Don't forget to get the SHA1 file for verification:</li>
<li><code v-pre>wget https://mrchromebox.tech/files/firmware/full_rom/coreboot_tiano-edgar-mrchromebox_20180827.rom.sha1</code></li>
<li>Then verify the download:</li>
<li><code v-pre>sha1sum -c coreboot_tiano-edgar-mrchromebox_20180827.rom.sha1</code></li>
</ul>
</li>
</ul>
</li>
<li>The shellball firmware for the device
<ul>
<li>As with the UEFI firmware above, the shellball ROM can be downloaded by concatenating the shellball base path with the device-specific filename:
<ul>
<li><code v-pre>wget &lt;shellball base path&gt;/shellball.&lt;device name&gt;.bin</code></li>
<li>Example for the Acer Chromebook 14 CB3-431 (EDGAR):</li>
<li><code v-pre>wget https://mrchromebox.tech/files/firmware/shellball/shellball.edgar.bin</code></li>
</ul>
</li>
</ul>
</li>
</ul>
</li>
</ol>
<div class="hint-container tip">
<p class="hint-container-title">Tips</p>
<p>If you're not sure which file to use for your device / don't know your device's board name, you can reference <RouteLink to="/docs/supported-devices.html">the supported devices page</RouteLink>.</p>
</div>
<h3 id="persisting-the-board-s-vital-product-data-vpd-and-hardware-id-hwid" tabindex="-1"><a class="header-anchor" href="#persisting-the-board-s-vital-product-data-vpd-and-hardware-id-hwid"><span>Persisting the board's Vital Product Data (VPD) and Hardware ID (HWID)</span></a></h3>
<p>The firmware in all ChromeOS devices contains a section (RO_VPD) which stores board-specific data, like the serial number, localization settings, and on many devices which have an Ethernet port, the LAN MAC address as well. When flashing via the Firmware Utility Script, the script will automatically extract this from the running firmware and inject it into the firmware to be flashed, so the device serial, LAN MAC address, etc are all maintained. Without this, the device will use a default/generic LAN MAC address set by coreboot. While not ideal, this is only really an issue if two or more of the same device are on the same LAN segment (or you're statically assigning IP addresses based on MAC). But for completeness, if flashing the UEFI firmware or shellball ROM, we'll extract the VPD (either from the board itself or a backup made by the script) and inject it into the firmware to be flashed.</p>
<div class="hint-container tip">
<p class="hint-container-title">NOTE</p>
<p>You don't need to do this if flashing a stock firmware backup created by the Firmware Utility Script; that image already contains the VPD.</p>
</div>
<ol>
<li>For both the options below, we'll need to use the gbb_utility and cbfstool (coreboot filesystem) binaries, so let's download/extract those:
<ul>
<li><code v-pre>wget https://mrchromebox.tech/files/util/cbfstool.tar.gz &amp;&amp; tar -zxf cbfstool.tar.gz</code></li>
<li><code v-pre>wget https://mrchromebox.tech/files/util/gbb_utility.tar.gz &amp;&amp; tar -zxf gbb_utility.tar.gz</code></li>
<li>Option 1: Extract VPD and HWID from the firmware on device
<ul>
<li><code v-pre>sudo flashrom -p raiden_debug_spi:target=AP -r badflash.rom</code></li>
<li><code v-pre>./cbfstool badflash.rom read -r RO_VPD -f vpd.bin</code></li>
<li><code v-pre>./gbb_utility badflash.rom --get --hwid | sed 's/^hardware_id: //' &gt; hwid.txt</code></li>
</ul>
</li>
<li>Option 2: Extract VPD and HWID from stock firmware backup created by Firmware Utility Script (this assumes the file has been copied into working directory)
<ul>
<li><code v-pre>./cbfstool stock-firmware-&lt;devicename&gt;-&lt;date&gt;.rom read -r RO_VPD -f vpd.bin</code></li>
<li><code v-pre>./gbb_utility stock-firmware-&lt;devicename&gt;-&lt;date&gt;.rom --get --hwid | sed 's/^hardware_id: //' &gt; hwid.txt</code></li>
</ul>
</li>
</ul>
</li>
<li>Then we inject the VPD and HWID into the firmware image to be flashed.
<ul>
<li><code v-pre>./cbfstool &lt;Shellball ROM/UEFI Full ROM filename&gt; write -r RO_VPD -f vpd.bin</code></li>
<li>For UEFI Full ROM run
<ul>
<li><code v-pre>./cbfstool &lt;UEFI Full ROM filename&gt; add -n hwid -f hwid.txt -t raw</code></li>
</ul>
</li>
<li>For Shellball run
<ul>
<li><code v-pre>./gbb_utility &lt;Shellball ROM&gt; --set --hwid=&quot;$(cat hwid.txt)&quot;</code>
Now the firmware image is ready to be flashed, and will maintain the device's unique serial, LAN MAC address, etc.</li>
</ul>
</li>
</ul>
</li>
</ol>
<h2 id="flashing-your-device" tabindex="-1"><a class="header-anchor" href="#flashing-your-device"><span>Flashing Your Device</span></a></h2>
<p>Now that everything is prepped, time to flash the device.</p>
<ol>
<li>
<p>Flash the firmware:</p>
<ul>
<li>For Intel-based ChromeOS devices, flash using
<ul>
<li><code v-pre>sudo flashrom -p raiden_debug_spi:target=AP -w &lt;filename&gt; --ifd -i bios</code></li>
</ul>
</li>
<li>For all other devices, use
<ul>
<li><code v-pre>sudo flashrom -p raiden_debug_spi:target=AP -w &lt;filename&gt;</code></li>
</ul>
</li>
</ul>
<p>Where <code v-pre>&lt;filename&gt;</code> is the name of your backup file, UEFI firmware file, or shellball firmware file. This will usually take 3-5 mins to complete; flashrom will first read the flash chip, determine which sectors differ, erase those sectors, write the new data, then verify the data written. The initial CCD setup make take a minute or so and not show any progress.</p>
</li>
</ol>
<h3 id="clean-up" tabindex="-1"><a class="header-anchor" href="#clean-up"><span>Clean Up</span></a></h3>
<p>Once flashing is complete, disconnect the Suzy-Q cable. If the internal battery was not disconnected, the device will likely reboot as soon as flashing has completed. If the internal battery was disconnected, reconnect it and replace the bottom cover/keyboard. Flip over the device, connect external power, press the power button, and cross your fingers 😃</p>
</div></template>


